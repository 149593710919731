import React from 'react'
import FluidImage from '../FluidImage/FluidImage'
import './Person.scss'

const Person = ({ name, position, email, src }) => {
  return (
    <div className="person-wrapper masonry-item">
      {src && (
        <div className="header">
          <FluidImage src={src} title={name} />
          <div className="header-darken"></div>
        </div>
      )}
      <div className="body">
        {name && <h3 className="h5">{name}</h3>}
        {position && <p>{position}</p>}
        {email && (
          <a
            href={`mailto:${email}`}
            target={'_blank'}
            rel="noreferrer"
            className="break-all"
          >
            {email}
          </a>
        )}
      </div>
    </div>
  )
}

export default Person
