import React from "react";
import Layout from "../components/Layout/Layout";
import Person from "../components/Persons/Person";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import PageHero from "../components/PageHero/PageHero";
import MasonryGrid from "../components/Masonry/MasonryGrid";

const omOss = ({ data: { page } }) => {
  const { hero, subsection } = page.header;
  const { personal, ledning } = page.personal;
  return (
    <Layout>
      <Seo
        title={hero.title}
        description={subsection.text}
        image={hero?.image?.localFile?.childImageSharp.original?.src}
      />
      <PageHero
        title={hero.title}
        src={hero.image?.localFile}
        subtitle={subsection.subtitle}
        text={`${subsection.text}`}
      />

      <section
        className="border-t border-opacity-10 border-lightest pb-12"
        data-sal="fade"
        data-sal-duration="2000"
        data-sal-easing="ease"
      >
        <div className="container">
          <MasonryGrid title={"På UÅ Padel jobbar"}>
            {ledning?.person?.length &&
              ledning.person.map((person, index) => (
                <Person
                  key={index}
                  name={person.name}
                  email={person.email}
                  position={person.position}
                  src={person?.image?.localFile}
                />
              ))}
            {personal?.person?.length &&
              personal.person.map((person, index) => (
                <Person
                  key={index + 1 }
                  name={person.name}
                  email={person.email}
                  position={person.position}
                  src={person?.image?.localFile}
                />
              ))}
          </MasonryGrid>
        </div>
      </section>
    </Layout>
  );
};

export default omOss;

export const pageQuery = graphql`
  query AboutPage {
    page: wpPage(title: { eq: "Om oss" }) {
      id
      header {
        hero {
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        subsection {
          subtitle
          text
        }
      }
      personal {
        personal {
          person {
            name
            email
            position
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        ledning {
          person {
            name
            position
            email
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
